import { commitmentSatisfaction, customerKnowledge, financialSituation, longTermVision } from "../../../models/general-questions-answer";

export function getSurveyJson(teamMembers: string[], isMobile: boolean)
{
    const surveyJson = {
        showCompletedPage: false,
        progressBarType: "questions",
        pages: [
            {
                elements: [
                    {
                        name: "company-founded",
                        type: "text",
                        title: "When was the company founded?",
                        inputType: "number",
                        min: 1990,
                        max: 2024,
                        isRequired: true,
                    },
                    {
                        name: "role-in-the-company",
                        type: "comment",
                        title: "What are your primary responsibilities within the company?",
                        description: "Please write one or two short sentences about your role in the company.",
                        maxLength: 200,
                        isRequired: true,
                        validators: [{
                            type: "text",
                            minLength: 50,
                            text: "The answer must be at least 50 characters long."
                        }]
                    },
                    {
                        name: "industry-experience",
                        type: "text",
                        title: "How many years of industry experience do you have?",
                        inputType: "number",
                        min: 0,
                        max: 50,
                        validators: [{
                            "type": "numeric",
                            "text": "Value must be within the range of 0 to 50",
                            "minValue": 0,
                            "maxValue": 50
                        }],
                        isRequired: true,
                    },
                    {
                        type: "boolean",
                        name: "serial-ent",
                        title: "Do you consider yourself a serial entrepreneur – i.e. you have started more than one business?",
                        valueTrue: "Yes",
                        valueFalse: "No",
                        renderAs: "radio",
                        isRequired: true,
                    },
                ],
            },
            {
                elements: [
                    {
                        type: "dropdown",
                        name: "most-work-with",
                        title:
                            "Please choose the individual within your team who you work with most frequently.",
                        isRequired: true,
                        choices: teamMembers,
                    },
                    {
                        type: "matrix",
                        name: "founder-known-matrix",
                        title: "Have you ever worked with your teammates before?",
                        columns: [
                            {
                                value: "E",
                                text: "Yes, we worked together at another business as employees",
                            },
                            {
                                value: "S",
                                text: "Yes, we have co-founded another startup / project in the past",
                            },
                            {
                                value: "F",
                                text: "No, but we knew each other outside of work through friends / family / our respective. networks",
                            },
                            {
                                value: "N",
                                text: "No, we have never worked together before and I didn’t know them prior to co-founding this business together",
                            },
                        ],
                        rows: teamMembers.map(x => ({
                            value: x,
                            text: x,
                        })),
                        alternateRows: true,
                        isAllRowRequired: true,
                    },
                    {
                        type: "paneldynamic",
                        name: "company-threats-list",
                        minPanelCount: 3,
                        maxPanelCount: 5,
                        title:
                            "In your professional assessment, what do you perceive as the most significant threat to your company's success? (e.g., competition, feasibility issues, lack of funding)",
                        description: "Please, provide a list of between 3 and 5 potential threats.",
                        templateElements: [
                            {
                                type: "text",
                                name: "threat",
                                title: " ",
                                description: "Threat {panelIndex}",
                                isRequired: true,
                            },
                        ],
                    },
                    {
                        type: "ranking",
                        name: "company-threats-rank",
                        title:
                            "Please, prioritise the identified threats from the most critical to the least critical.",
                        isRequired: true,
                        choicesFromQuestion: "company-threats-list",
                        choicesFromQuestionMode: "selected",
                        choiceValuesFromQuestion: "threat",
                    },
                    {
                        name: "overall-commitment-hours",
                        type: "text",
                        title:
                            "What is the total number of work hours contributed by all founders to the project per week?",
                        inputType: "number",
                        min: 0,
                        max: 400,
                        isRequired: true,
                    },
                    {
                        type: "radiogroup",
                        name: "overall-commitment-satisfaction",
                        title: "Are you satisfied with this workload?",
                        showNoneItem: false,
                        showOtherItem: false,
                        colCount: 1,
                        choices: commitmentSatisfaction.map((x) => x.choice),
                        separateSpecialChoices: false,
                        showClearButton: false,
                        isRequired: true,
                    },
                ],
            },
            {
                elements: [
                    {
                        type: "matrix",
                        name: "commitment-matrix",
                        title: "Please, evaluate each founder's time commitment to the project using the following scale:",
                        columns: [
                            {
                                value: "SigMore",
                                text: "I expect the founder to work significantly more",
                            },
                            {
                                value: "More",
                                text: "I expect the founder to work more",
                            },
                            {
                                value: "NoChange",
                                text: "I expect the founder to maintain their current level of commitment",
                            },
                            {
                                value: "Less",
                                text: "I expect the founder to work less",
                            },
                            {
                                value: "SigLess",
                                text: "I expect the founder to work significantly less",
                            },
                        ],
                        rows: teamMembers.map(x => ({
                            value: x,
                            text: x,
                        })),
                        alternateRows: true,
                        isAllRowRequired: true,
                    },
                ],
            },
            {
                elements: [
                    {
                        type: "checkbox",
                        name: "financial-situation",
                        title: "How would you characterise the financial status of the company? (Please check all that apply!)",
                        choices: financialSituation.map((x) => x.choice),
                        isRequired: true,
                        minSelectedChoices: 2,
                        colCount: 2,
                    },
                    {
                        type: "ranking",
                        name: "financial-situation-rank",
                        title:
                            "Please prioritise the previously provided answers based on their significance in how you have managed the company's finances so far.",
                        isRequired: true,
                        choicesFromQuestion: "financial-situation",
                        choicesFromQuestionMode: "selected",
                    },
                    {
                        type: "rating",
                        name: "customers-rating",
                        title: "On a scale of 1 to 5, with 1 being minimal knowledge and 5 being expert-level knowledge, how would you rate your understanding of your customers?",
                        description: "If you think you know close to nothing about your customers rate this question to zero. If you think you know everything about your customers rate this question to five.",
                        rateCount: 6,
                        rateValues: [0, 1, 2, 3, 4, 5],
                        isRequired: true,
                    },
                ],
            },
            {
                elements: [
                    {
                        type: "checkbox",
                        name: "customers-basement",
                        title: "In your previous response, you rated your level of customer knowledge as {customers-rating}. Could you please elaborate on the factors or sources that influenced this rating?",
                        visibleIf: "{customers-rating} > 0",
                        choices: customerKnowledge.map((x) => x.choice),
                        isRequired: true,
                        colCount: 1,
                    },
                    {
                        type: "panel",
                        name: "rich-vs-king",
                        title: "Please, finish the following sentences with your preference.",
                        showQuestionNumbers: "onPanel",
                        elements: [{
                            name: "structure",
                            title: "The company's structure is tailored to facilitate:",
                            type: "radiogroup",
                            choices: ["Control", "Growth"],
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                        }, {
                            name: "lean",
                            type: "radiogroup",
                            title: "In terms of your fundamental beliefs, you lean towards:",
                            // titleLocation: "hidden",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Trusting the existing process", "Believing in the possibilities"]
                        },
                        {
                            name: "hiring",
                            type: "radiogroup",
                            title: "When it comes to the hiring process, what holds greater significance for you:",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Having a comprehensive understanding of the candidate", "Selecting the best candidate, even if they are unfamiliar to you"]
                        },
                        {
                            name: "growth",
                            type: "radiogroup",
                            title: "Concerning growth, my preference leans towards:",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Bootstraping", "Securing outside capital"]
                        },
                        {
                            name: "growth-pace",
                            type: "radiogroup",
                            title: "When it comes to your chosen growth strategy, which resonates with you more?",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Slow and steady wins the race", "Move fast and break things"]
                        },
                        {
                            name: "goal",
                            type: "radiogroup",
                            title: "I would rather opt for:",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Maintaining maximum control over the company with a solid valuation", "Achieving a higher valuation, but with shared (and therefore, less) control over the company"]
                        },
                        ],
                    },
                    {
                        type: "radiogroup",
                        name: "longterm-vision",
                        title: "What is your long term goal with the company?",
                        description: "Where do you imagine the company in the next years?",
                        showNoneItem: false,
                        colCount: 1,
                        choices: longTermVision.map((x) => x.choice),
                        separateSpecialChoices: false,
                        showClearButton: false,
                        isRequired: true,
                    },
                    {
                        name: "longterm-vision-duration",
                        type: "text",
                        title:
                            "In your opinion, how many years does it take for the company to reach the long term vision goal you just selected in the previous question?",
                        inputType: "number",
                        min: 0,
                        max: 40,
                        isRequired: true,
                    },
                ],
            },
        ],
        showQuestionNumbers: false,
        widthMode: "static",
        width: isMobile ? "100%" : "50%",
    };

    return surveyJson;
}

export function getSurveyJsonFQShortened(teamMembers: string[], isMobile: boolean) {
    const surveyJson = {
        showCompletedPage: false,
        progressBarType: "questions",
        pages: [
            {
                elements: [
                    {
                        name: "industry-experience",
                        type: "text",
                        title: "How many years of industry experience do you have?",
                        inputType: "number",
                        min: 0,
                        max: 50,
                        validators: [{
                            "type": "numeric",
                            "text": "Value must be within the range of 0 to 50",
                            "minValue": 0,
                            "maxValue": 50
                        }],
                        isRequired: true,
                    },
                    {
                        type: "boolean",
                        name: "serial-ent",
                        title: "Do you consider yourself a serial entrepreneur – i.e. you have started more than one business?",
                        valueTrue: "Yes",
                        valueFalse: "No",
                        renderAs: "radio",
                        isRequired: true,
                    },
                ],
            },
            {
                elements: [
                    {
                        type: "paneldynamic",
                        name: "company-threats-list",
                        minPanelCount: 3,
                        maxPanelCount: 5,
                        title:
                            "In your professional assessment, what do you perceive as the most significant threat to your company's success? (e.g., competition, feasibility issues, lack of funding)",
                        description: "Please, provide a list of between 3 and 5 potential threats.",
                        templateElements: [
                            {
                                type: "text",
                                name: "threat",
                                title: " ",
                                description: "Threat {panelIndex}",
                                isRequired: true,
                            },
                        ],
                    },
                    {
                        type: "ranking",
                        name: "company-threats-rank",
                        title:
                            "Please, prioritise the identified threats from the most critical to the least critical.",
                        isRequired: true,
                        choicesFromQuestion: "company-threats-list",
                        choicesFromQuestionMode: "selected",
                        choiceValuesFromQuestion: "threat",
                    },
                ],
            },
            {
                elements: [
                    {
                        type: "rating",
                        name: "customers-rating",
                        title: "On a scale of 1 to 5, with 1 being minimal knowledge and 5 being expert-level knowledge, how would you rate your understanding of your customers?",
                        description: "If you think you know close to nothing about your customers rate this question to zero. If you think you know everything about your customers rate this question to five.",
                        rateCount: 6,
                        rateValues: [0, 1, 2, 3, 4, 5],
                        isRequired: true,
                    },
                    {
                        type: "checkbox",
                        name: "customers-basement",
                        title: "In your previous response, you rated your level of customer knowledge as {customers-rating}. Could you please elaborate on the factors or sources that influenced this rating?",
                        visibleIf: "{customers-rating} > 0",
                        choices: customerKnowledge.map((x) => x.choice),
                        isRequired: true,
                        colCount: 1,
                    },
                    {
                        type: "panel",
                        name: "rich-vs-king",
                        title: "Please, finish the following sentences with your preference.",
                        showQuestionNumbers: "onPanel",
                        elements: [{
                            name: "structure",
                            title: "The company's structure is tailored to facilitate:",
                            type: "radiogroup",
                            choices: ["Control", "Growth"],
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                        }, {
                            name: "lean",
                            type: "radiogroup",
                            title: "In terms of your fundamental beliefs, you lean towards:",
                            // titleLocation: "hidden",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Trusting the existing process", "Believing in the possibilities"]
                        },
                        {
                            name: "hiring",
                            type: "radiogroup",
                            title: "When it comes to the hiring process, what holds greater significance for you:",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Having a comprehensive understanding of the candidate", "Selecting the best candidate, even if they are unfamiliar to you"]
                        },
                        {
                            name: "growth",
                            type: "radiogroup",
                            title: "Concerning growth, my preference leans towards:",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Bootstraping", "Securing outside capital"]
                        },
                        {
                            name: "growth-pace",
                            type: "radiogroup",
                            title: "When it comes to your chosen growth strategy, which resonates with you more?",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Slow and steady wins the race", "Move fast and break things"]
                        },
                        {
                            name: "goal",
                            type: "radiogroup",
                            title: "I would rather opt for:",
                            startWithNewLine: true,
                            isRequired: true,
                            colCount: 0,
                            choices: ["Maintaining maximum control over the company with a solid valuation", "Achieving a higher valuation, but with shared (and therefore, less) control over the company"]
                        },
                        ],
                    },
                    {
                        type: "radiogroup",
                        name: "longterm-vision",
                        title: "What is your long term goal with the company?",
                        description: "Where do you imagine the company in the next years?",
                        showNoneItem: false,
                        colCount: 1,
                        choices: longTermVision.map((x) => x.choice),
                        separateSpecialChoices: false,
                        showClearButton: false,
                        isRequired: true,
                    },
                    {
                        name: "longterm-vision-duration",
                        type: "text",
                        title:
                            "In your opinion, how many years does it take for the company to reach the long term vision goal you just selected in the previous question?",
                        inputType: "number",
                        min: 0,
                        max: 40,
                        isRequired: true,
                    },
                ],
            },
        ],
        showQuestionNumbers: false,
        widthMode: "static",
        width: isMobile ? "100%" : "50%",
    };

    return surveyJson;
}